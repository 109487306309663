<template>
  <div class="page__wrapper">
    <ag-grid
      ref="reportReviewGrid"
      pagination
      style="width: 100%; height: 100%"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :pagination-page-size="tablePageInfo.pageSize"
      :framework-components="frameworkComponents"
      :grid-options="gridOptions"
    >
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t("operation.search") }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
    <!-- 详情页 -->
    <detail-dialog
      :show-flag.sync="isShowDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <!-- 详情页 New -->
    <new-detail-dialog
      :show-flag.sync="isShowNewDetailDialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <!-- 详情页 2022 -->
    <detail-2022-dialog
      :show-flag.sync="isShowDetail2022Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <!-- 详情页 202206 -->
    <detail-202206-dialog
      :show-flag.sync="isShowDetail202206Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <!-- 详情页 202308 -->
    <detail-202308-dialog
      :show-flag.sync="isShowDetail202308Dialog"
      :detail-data="rowData"
      :view-flag="isViewFlag"
    />

    <appeal
      :show-flag.sync="isShowAppealDialog"
      :detail-data="rowData"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import detailDialog from '../../dealer/finance/detail'
import newDetailDialog from '../../dealer/finance/detailNew'
import detail2022Dialog from '../../dealer/finance/detail2022'
import detail202206Dialog from '../../dealer/finance/detail202206'
import detail202308Dialog from '../../dealer/finance/detail202308'
import appeal from './appeal'
import pageMixins from '@/components/base/simple-table/mixin'

const BASEURL = {
  list: '/jlr/tt/selfExaminationData/list',
  office: '/system/office/list',
  export: '/jlr/tt/selfExaminationData/export'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: [ 'handleDataSearch', 'handleViewClick', 'handleAppealClick' ],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.preview')">
        <el-button type="text" v-permission="'thirdParty_appealContent:view'" style="font-size:14px;"
          icon="el-icon-search" @click="handleViewClick(params.data)" />
      </el-tooltip>
      <el-tooltip effect="light" :content="$t('thirdParty_appealContent.viewAppealContent')">
        <el-button type="text" v-permission="'thirdParty_appealContent:viewAppealContent'" style="font-size:14px;"
          :disabled="params.data.reviewConfirmStatus!=='tt_review_confirm_status_2'" icon="el-icon-view"
            @click="handleAppealClick(params.data)" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'AppealContent',
  components: {
    detailDialog,
    newDetailDialog,
    detail2022Dialog,
    detail202206Dialog,
    detail202308Dialog,
    appeal
  },
  mixins: [ pageMixins ],
  data () {
    return {
      rowData: {},
      tableData: [],
      dealerList: [],
      groupList: [],
      gridOptions: {},
      searchModel: {
        period: this.$moment().subtract(1, 'months').format('YYYYMM')
      },
      isViewFlag: false,
      frameworkComponents: {},
      isShowDetailDialog: false,
      isShowAppealDialog: false,
      groupLoading: false,
      dealerLoading: false,
      isShowNewDetailDialog: false,
      isShowDetail2022Dialog: false,
      isShowDetail202206Dialog: false,
      isShowDetail202308Dialog: false
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleViewClick: this.handleViewClick,
      handleAppealClick: this.handleAppealClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('thirdParty_reportExport.month'),
          component: { clearable: false, type: 'month', valueFormat: 'yyyyMM' }
        },
        {
          prop: 'region',
          type: 'Select',
          col: { xs: 4, sm: 4, md: 4 },
          component: {
            optionList: this.$getDictList('sys_office_region'),
            clearable: true
          },
          label: this.$t('dealer_finance.area')
        },
        {
          prop: 'dealerParent',
          type: 'SearchableInput',
          col: { xs: 4, sm: 4, md: 4 },
          label: this.$t('dealer_finance.group'),
          component: {
            remoteMethod: this.getGroupList,
            loading: this.groupLoading,
            optionList: this.groupList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getGroupList()
            }
          }
        },
        {
          prop: 'dealer',
          label: this.$t('dealer_finance.dealer'),
          type: 'SearchableInput',
          col: { xs: 4, sm: 4, md: 4 },
          component: {
            remoteMethod: this.getDealerList,
            loading: this.dealerLoading,
            optionList: this.dealerList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getDealerList()
            }
          }
        },
        {
          slotName: 'tableOperation',
          col: { xs: 8, sm: 8, md: 8 },
          style: { textAlign: 'right' },
          labelWidth: '0'
        }
      ]
    },
    columnDefs () {
      return [
        {
          headerName: this.$t('dealer_finance.dealerName'),
          field: 'dealer.name',
          pinned: 'left',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.dealerCode'),
          field: 'dealer.code',
          pinned: 'left',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.area'),
          field: 'region',
          valueFormatter: (params) =>
            this.$getDictLabel({
              type: 'sys_office_region',
              value: params.value
            }),
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.group'),
          field: 'dealerParent',
          valueFormatter: (params) =>
            params.data.dealerParent ? params.data.dealerParent.name || '' : '',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return valueA && valueB
              ? valueA.name.localeCompare(valueB.name)
              : -1
          },
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.version'),
          field: 'version',
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.cycle'),
          field: 'period',
          valueFormatter: (params) =>
            params.value
              ? this.$moment(params.value, 'YYYYMM').format('YYYY年MM月')
              : '',
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.reporter'),
          field: 'compiler',
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.reporterPosition'),
          field: 'compilerPosition',
          minWidth: 160
        },
        // {
        //   headerName: this.$t('dealer_finance.reporterPhone'),
        //   field: 'compilerPhone',
        //   minWidth: 160
        // },
        {
          headerName: this.$t('dealer_finance.reporterEmail'),
          field: 'compilerMail',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.createDate'),
          field: 'createDate',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.status'),
          field: 'bizStatus',
          valueFormatter: (params) =>
            this.$getDictLabel({
              type: 'tt_self_examination_data_head_biz_status',
              value: params.value
            }),
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.reviewConfirmStatus'),
          field: 'reviewConfirmStatus',
          valueFormatter: (params) =>
            this.$getDictLabel({
              type: 'tt_review_confirm_status',
              value: params.value
            }),
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.reviewConfirmUser'),
          field: 'reviewConfirmUser',
          minWidth: 160
        },
        {
          headerName: this.$t('field.operation'),
          width: 80,
          suppressSizeToFit: true,
          pinned: 'right',
          cellRenderer: 'Operation'
        }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.getDealerList()
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign(
        {},
        this.$utils.parseQueryCondition(this.searchModel, this.searchFormField)
      )
      params.filter.push({ left: 'bizStatus', operate: '=', right: 'V3' })
      params.filter.push({ left: 'isSubmit', operate: '=', right: 'Y' })
      if (this.searchModel.dealer) {
        params.filter.push({
          left: 'dealer.id',
          operate: '=',
          right: this.searchModel.dealer.id
        })
      }
      if (this.searchModel.dealerParent) {
        params.filter.push({
          left: 'dealerParent.id',
          operate: '=',
          right: this.searchModel.dealerParent.id
        })
      }
      const loadingFlag = this.$loading({
        fullscreen: false,
        target: this.$el,
        text: this.$t('tip.systemLoading')
      })
      this.$axios
        .post(BASEURL.list, params)
        .then((resp) => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleViewClick (row) {
      this.rowData = this.$_.cloneDeep(row)
      this.isViewFlag = true
      if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202101', 'YYYYMM')
      ) {
        this.isShowDetailDialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202201', 'YYYYMM')
      ) {
        this.isShowNewDetailDialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202206', 'YYYYMM')
      ) {
        this.isShowDetail2022Dialog = true
      } else if (
        this.$moment(row.period, 'YYYYMM') < this.$moment('202308', 'YYYYMM')
      ) {
        this.isShowDetail202206Dialog = true
      } else {
        this.isShowDetail202308Dialog = true
      }
    },
    getDealerList (name) {
      this.dealerLoading = true
      const params = {
        filter: [
          { left: 'category', operate: '=', right: 'sys_office_category_3' }
        ]
      }
      if (name) {
        params.filter.push({
          left: { left: 'name', operate: 'like', right: name },
          operate: 'or',
          right: { left: 'code', operate: 'like', right: name }
        })
      }
      this.$axios
        .post(BASEURL.office, params)
        .then((resp) => {
          const respData = resp.data
          this.dealerList = respData.list.map((item) => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.dealerLoading = false
        })
    },
    getGroupList (name) {
      this.groupLoading = true
      const params = {
        filter: [
          { left: 'category', operate: '=', right: 'sys_office_category_2' }
        ]
      }
      if (name) {
        params.filter.push({
          left: { left: 'name', operate: 'like', right: name },
          operate: 'or',
          right: { left: 'nameEn', operate: 'like', right: name }
        })
      }
      this.$axios
        .post(BASEURL.office, params)
        .then((resp) => {
          const respData = resp.data
          this.groupList = respData.list.map((item) => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.groupLoading = false
        })
    },
    handleAppealClick (rowData) {
      this.rowData = this.$_.cloneDeep(rowData)
      this.isShowAppealDialog = true
    }
  }
}
</script>
<style lang="less" scoped>
.page__wrapper {
  width: 100%;
  height: 100%;
}
</style>
